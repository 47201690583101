<template>
  <div @click="copyColour()" ref="currentDiv" title="Copy hex code" :style="{ backgroundColor: color }">
    <input ref="colorInput" type="text" :value="hexColor" />
    <span v-if="!validColor">Invalid colour: {{ color }}</span>
  </div>
</template>

<script>
import tinycolor from 'tinycolor2'

export default {
  name: 'ColorBlock',
  props: {
    color: String
  },
  data () {
    return {
      blockColor: tinycolor(this.color)
    }
  },
  computed: {
    validColor () {
      return this.blockColor.isValid()
    },
    hexColor () {
      return this.blockColor.toHex()
    }
  },
  methods: {
    copyColour () {
      this.$refs.colorInput.select()
      this.$refs.colorInput.setSelectionRange(0, 99999)
      document.execCommand('copy')
      this.$refs.colorInput.blur()
    }
  }
}
</script>

<style scoped>
div {
  vertical-align: top;
  display: inline-block;
  max-width: 75px;
  width: 100%;
  height: 75px;
  margin: 0;
  border-radius: 0;
  cursor: copy;
  position: relative;
}

div:active:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.2);
  mix-blend-mode: exclusion;
  pointer-events: none;
}

input {
  width: 1px;
  height: 0;
  opacity: 0;
  position: fixed;
  left: -100%;
  top: -100%;
  pointer-events: none;
}
</style>
