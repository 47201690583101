export default {
  blueGray: [
    { lightness: 50, hex: '#F8FAFC' },
    { lightness: 100, hex: '#F1F5F9' },
    { lightness: 200, hex: '#E2E8F0' },
    { lightness: 300, hex: '#CBD5E1' },
    { lightness: 400, hex: '#94A3B8' },
    { lightness: 500, hex: '#64748B' },
    { lightness: 600, hex: '#475569' },
    { lightness: 700, hex: '#334155' },
    { lightness: 800, hex: '#1E293B' },
    { lightness: 900, hex: '#0F172A' }
  ],
  coolGray: [
    { lightness: 50, hex: '#F9FAFB' },
    { lightness: 100, hex: '#F3F4F6' },
    { lightness: 200, hex: '#E5E7EB' },
    { lightness: 300, hex: '#D1D5DB' },
    { lightness: 400, hex: '#9CA3AF' },
    { lightness: 500, hex: '#6B7280' },
    { lightness: 600, hex: '#4B5563' },
    { lightness: 700, hex: '#374151' },
    { lightness: 800, hex: '#1F2937' },
    { lightness: 900, hex: '#111827' }
  ],
  gray: [
    { lightness: 50, hex: '#FAFAFA' },
    { lightness: 100, hex: '#F4F4F5' },
    { lightness: 200, hex: '#E4E4E7' },
    { lightness: 300, hex: '#D4D4D8' },
    { lightness: 400, hex: '#A1A1AA' },
    { lightness: 500, hex: '#71717A' },
    { lightness: 600, hex: '#52525B' },
    { lightness: 700, hex: '#3F3F46' },
    { lightness: 800, hex: '#27272A' },
    { lightness: 900, hex: '#18181B' }
  ],
  trueGray: [
    { lightness: 50, hex: '#FAFAFA' },
    { lightness: 100, hex: '#F5F5F5' },
    { lightness: 200, hex: '#E5E5E5' },
    { lightness: 300, hex: '#D4D4D4' },
    { lightness: 400, hex: '#A3A3A3' },
    { lightness: 500, hex: '#737373' },
    { lightness: 600, hex: '#525252' },
    { lightness: 700, hex: '#404040' },
    { lightness: 800, hex: '#262626' },
    { lightness: 900, hex: '#171717' }
  ],
  warmGray: [
    { lightness: 50, hex: '#FAFAF9' },
    { lightness: 100, hex: '#F5F5F4' },
    { lightness: 200, hex: '#E7E5E4' },
    { lightness: 300, hex: '#D6D3D1' },
    { lightness: 400, hex: '#A8A29E' },
    { lightness: 500, hex: '#78716C' },
    { lightness: 600, hex: '#57534E' },
    { lightness: 700, hex: '#44403C' },
    { lightness: 800, hex: '#292524' },
    { lightness: 900, hex: '#1C1917' }
  ],
  red: [
    { lightness: 50, hex: '#FEF2F2' },
    { lightness: 100, hex: '#FEE2E2' },
    { lightness: 200, hex: '#FECACA' },
    { lightness: 300, hex: '#FCA5A5' },
    { lightness: 400, hex: '#F87171' },
    { lightness: 500, hex: '#EF4444' },
    { lightness: 600, hex: '#DC2626' },
    { lightness: 700, hex: '#B91C1C' },
    { lightness: 800, hex: '#991B1B' },
    { lightness: 900, hex: '#7F1D1D' }
  ],
  orange: [
    { lightness: 50, hex: '#FFF7ED' },
    { lightness: 100, hex: '#FFEDD5' },
    { lightness: 200, hex: '#FED7AA' },
    { lightness: 300, hex: '#FDBA74' },
    { lightness: 400, hex: '#FB923C' },
    { lightness: 500, hex: '#F97316' },
    { lightness: 600, hex: '#EA580C' },
    { lightness: 700, hex: '#C2410C' },
    { lightness: 800, hex: '#9A3412' },
    { lightness: 900, hex: '#7C2D12' }
  ],
  amber: [
    { lightness: 50, hex: '#FFFBEB' },
    { lightness: 100, hex: '#FEF3C7' },
    { lightness: 200, hex: '#FDE68A' },
    { lightness: 300, hex: '#FCD34D' },
    { lightness: 400, hex: '#FBBF24' },
    { lightness: 500, hex: '#F59E0B' },
    { lightness: 600, hex: '#D97706' },
    { lightness: 700, hex: '#B45309' },
    { lightness: 800, hex: '#92400E' },
    { lightness: 900, hex: '#78350F' }
  ],
  yellow: [
    { lightness: 50, hex: '#FEFCE8' },
    { lightness: 100, hex: '#FEF9C3' },
    { lightness: 200, hex: '#FEF08A' },
    { lightness: 300, hex: '#FDE047' },
    { lightness: 400, hex: '#FACC15' },
    { lightness: 500, hex: '#EAB308' },
    { lightness: 600, hex: '#CA8A04' },
    { lightness: 700, hex: '#A16207' },
    { lightness: 800, hex: '#854D0E' },
    { lightness: 900, hex: '#713F12' }
  ],
  lime: [
    { lightness: 50, hex: '#F7FEE7' },
    { lightness: 100, hex: '#ECFCCB' },
    { lightness: 200, hex: '#D9F99D' },
    { lightness: 300, hex: '#BEF264' },
    { lightness: 400, hex: '#A3E635' },
    { lightness: 500, hex: '#84CC16' },
    { lightness: 600, hex: '#65A30D' },
    { lightness: 700, hex: '#4D7C0F' },
    { lightness: 800, hex: '#3F6212' },
    { lightness: 900, hex: '#365314' }
  ],
  green: [
    { lightness: 50, hex: '#F0FDF4' },
    { lightness: 100, hex: '#DCFCE7' },
    { lightness: 200, hex: '#BBF7D0' },
    { lightness: 300, hex: '#86EFAC' },
    { lightness: 400, hex: '#4ADE80' },
    { lightness: 500, hex: '#22C55E' },
    { lightness: 600, hex: '#16A34A' },
    { lightness: 700, hex: '#15803D' },
    { lightness: 800, hex: '#166534' },
    { lightness: 900, hex: '#14532D' }
  ],
  emerald: [
    { lightness: 50, hex: '#ECFDF5' },
    { lightness: 100, hex: '#D1FAE5' },
    { lightness: 200, hex: '#A7F3D0' },
    { lightness: 300, hex: '#6EE7B7' },
    { lightness: 400, hex: '#34D399' },
    { lightness: 500, hex: '#10B981' },
    { lightness: 600, hex: '#059669' },
    { lightness: 700, hex: '#047857' },
    { lightness: 800, hex: '#065F46' },
    { lightness: 900, hex: '#064E3B' }
  ],
  teal: [
    { lightness: 50, hex: '#F0FDFA' },
    { lightness: 100, hex: '#CCFBF1' },
    { lightness: 200, hex: '#99F6E4' },
    { lightness: 300, hex: '#5EEAD4' },
    { lightness: 400, hex: '#2DD4BF' },
    { lightness: 500, hex: '#14B8A6' },
    { lightness: 600, hex: '#0D9488' },
    { lightness: 700, hex: '#0F766E' },
    { lightness: 800, hex: '#115E59' },
    { lightness: 900, hex: '#134E4A' }
  ],
  cyan: [
    { lightness: 50, hex: '#ECFEFF' },
    { lightness: 100, hex: '#CFFAFE' },
    { lightness: 200, hex: '#A5F3FC' },
    { lightness: 300, hex: '#67E8F9' },
    { lightness: 400, hex: '#22D3EE' },
    { lightness: 500, hex: '#06B6D4' },
    { lightness: 600, hex: '#0891B2' },
    { lightness: 700, hex: '#0E7490' },
    { lightness: 800, hex: '#155E75' },
    { lightness: 900, hex: '#164E63' }
  ],
  lightBlue: [
    { lightness: 50, hex: '#F0F9FF' },
    { lightness: 100, hex: '#E0F2FE' },
    { lightness: 200, hex: '#BAE6FD' },
    { lightness: 300, hex: '#7DD3FC' },
    { lightness: 400, hex: '#38BDF8' },
    { lightness: 500, hex: '#0EA5E9' },
    { lightness: 600, hex: '#0284C7' },
    { lightness: 700, hex: '#0369A1' },
    { lightness: 800, hex: '#075985' },
    { lightness: 900, hex: '#0C4A6E' }
  ],
  blue: [
    { lightness: 50, hex: '#EFF6FF' },
    { lightness: 100, hex: '#DBEAFE' },
    { lightness: 200, hex: '#BFDBFE' },
    { lightness: 300, hex: '#93C5FD' },
    { lightness: 400, hex: '#60A5FA' },
    { lightness: 500, hex: '#3B82F6' },
    { lightness: 600, hex: '#2563EB' },
    { lightness: 700, hex: '#1D4ED8' },
    { lightness: 800, hex: '#1E40AF' },
    { lightness: 900, hex: '#1E3A8A' }
  ],
  indigo: [
    { lightness: 50, hex: '#EEF2FF' },
    { lightness: 100, hex: '#E0E7FF' },
    { lightness: 200, hex: '#C7D2FE' },
    { lightness: 300, hex: '#A5B4FC' },
    { lightness: 400, hex: '#818CF8' },
    { lightness: 500, hex: '#6366F1' },
    { lightness: 600, hex: '#4F46E5' },
    { lightness: 700, hex: '#4338CA' },
    { lightness: 800, hex: '#3730A3' },
    { lightness: 900, hex: '#312E81' }
  ],
  violet: [
    { lightness: 50, hex: '#F5F3FF' },
    { lightness: 100, hex: '#EDE9FE' },
    { lightness: 200, hex: '#DDD6FE' },
    { lightness: 300, hex: '#C4B5FD' },
    { lightness: 400, hex: '#A78BFA' },
    { lightness: 500, hex: '#8B5CF6' },
    { lightness: 600, hex: '#7C3AED' },
    { lightness: 700, hex: '#6D28D9' },
    { lightness: 800, hex: '#5B21B6' },
    { lightness: 900, hex: '#4C1D95' }
  ],
  purple: [
    { lightness: 50, hex: '#FAF5FF' },
    { lightness: 100, hex: '#F3E8FF' },
    { lightness: 200, hex: '#E9D5FF' },
    { lightness: 300, hex: '#D8B4FE' },
    { lightness: 400, hex: '#C084FC' },
    { lightness: 500, hex: '#A855F7' },
    { lightness: 600, hex: '#9333EA' },
    { lightness: 700, hex: '#7E22CE' },
    { lightness: 800, hex: '#6B21A8' },
    { lightness: 900, hex: '#581C87' },
  ],
  fuchsia: [
    { lightness: 50, hex: '#FDF4FF' },
    { lightness: 100, hex: '#FAE8FF' },
    { lightness: 200, hex: '#F5D0FE' },
    { lightness: 300, hex: '#F0ABFC' },
    { lightness: 400, hex: '#E879F9' },
    { lightness: 500, hex: '#D946EF' },
    { lightness: 600, hex: '#C026D3' },
    { lightness: 700, hex: '#A21CAF' },
    { lightness: 800, hex: '#86198F' },
    { lightness: 900, hex: '#701A75' },
  ],
  pink: [
    { lightness: 50, hex: '#FDF2F8' },
    { lightness: 100, hex: '#FCE7F3' },
    { lightness: 200, hex: '#FBCFE8' },
    { lightness: 300, hex: '#F9A8D4' },
    { lightness: 400, hex: '#F472B6' },
    { lightness: 500, hex: '#EC4899' },
    { lightness: 600, hex: '#DB2777' },
    { lightness: 700, hex: '#BE185D' },
    { lightness: 800, hex: '#9D174D' },
    { lightness: 900, hex: '#831843' }
  ],
  rose: [
    { lightness: 50, hex: '#FFF1F2' },
    { lightness: 100, hex: '#FFE4E6' },
    { lightness: 200, hex: '#FECDD3' },
    { lightness: 300, hex: '#FDA4AF' },
    { lightness: 400, hex: '#FB7185' },
    { lightness: 500, hex: '#F43F5E' },
    { lightness: 600, hex: '#E11D48' },
    { lightness: 700, hex: '#BE123C' },
    { lightness: 800, hex: '#9F1239' },
    { lightness: 900, hex: '#881337' }
  ]
}
