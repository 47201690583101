import { createApp } from 'vue'

// import Amplify from 'aws-amplify'
// import { applyPolyfills, defineCustomElements } from '@aws-amplify/ui-components/loader'
// import aws_exports from './aws-exports'


import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

// Amplify.configure(aws_exports)
// applyPolyfills().then(() => defineCustomElements(window))

const app = createApp(App)//.use(store).use(router).mount('#app')
//app.config.isCustomElement = (tag) => tag.startsWith('amplify-')
app.use(store).use(router).mount('#app')
