<template>
  <div class="colorlist">
    <!--<ColorBlock v-for="col in colors" :color="col" :key="col"></ColorBlock>
    <ColorBlock color="#fff"></ColorBlock>-->
    <ColorBlock v-for="col in colorList" :color="col" :key="col"></ColorBlock>
  </div>
</template>

<script>
import ColorBlock from '@/components/elements/ColorBlock'
// import paletteGenerator from '@/utils/palette-generator'
import shades3 from '@/neural/shades3'
import mixshade from '@/neural/mixshade'

export default {
  name: 'ColorList',
  components: { ColorBlock },
  props: {
    colors: Array,
    palette: String,
    amount: Number,
    brightness: Number
  },
  data () {
    return {

    }
  },
  computed: {
    colorList () {
      //const func = paletteGenerator[this.palette]
      const func = this.colors.length > 1 ? mixshade : shades3
      if (func) {
        return func(this.colors, this.amount, this.brightness)
      } else {
        return ['black']
      }
    }
  }
}
</script>

<style scoped>
.colorlist {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.colorlist > * {
  flex-grow: 2;
}
</style>
