/* eslint-disable */
import tinycolor from 'tinycolor2'
import colorList from './colors/colorList'
import gradientTraining from './training/mixshade-training-1.json'

const LOAD_TRAINING = true
const CURRENT_TRAINING = gradientTraining

const net = new brain.NeuralNetwork({
  activation: 'sigmoid',
  hiddenLayers: [5, 4, 3, 2]
})

loadOrTrain()

/**
 * Take one colour, output an array of x colours in various shades
 * @param colors
 * @returns {*[]|*}
 */
export default function (colors) {
  const color1 = tinycolor(colors[0])
  const color2 = tinycolor(colors[1])
  if (!color1.isValid() && !color2.isValid()) {
    console.log('Colour(s) not valid:', colors[0], colors[1])
    return colors
  }

  const color1Rgb = color1.toRgb()
  const color2Rgb = color2.toRgb()
  const result = net.run({
    r1: to100(color1Rgb.r),
    g1: to100(color1Rgb.g),
    b1: to100(color1Rgb.b),
    r2: to100(color2Rgb.r),
    g2: to100(color2Rgb.g),
    b2: to100(color2Rgb.b)
  })

  const formattedResult = convertResult(result)
  return [
    ...formattedResult
  ]
}

/**
 * Load or train
 */
function loadOrTrain () {
  if (LOAD_TRAINING) {
    loadNet(CURRENT_TRAINING)
  } else {
    trainNet()
  }
}

/**
 * Load brain
 * @param json - JSON to load
 */
function loadNet (json) {
  net.fromJSON(json)
}

/**
 * Train brain
 */
function trainNet () {
  const shuffle = (array) => array.sort(() => Math.random() - 0.5)
  const input = shuffle(createInput())
  console.log(input)
  net.train(input, {
    learningRate: 0.1,
    errorThresh: 0.0002,
    iterations: 40000,
    logPeriod: 1000,
    log: (error) => console.log(error)
  })
  const json = net.toJSON()
  console.log(JSON.stringify(json))
}

/**
 * Convert brain output into array of colours
 * @param input
 * @returns {[]}
 */
function convertResult (input) {
  const cols = {}
  for (const [name, val] of Object.entries(input)) {
    cols[name.substring(1)] = cols[name.substring(1)] || {}
    cols[name.substring(1)][name[0]] = to256(val.toFixed(2))
  }

  const colArray = []
  for (const { r, g, b } of Object.values(cols)) {
    colArray.push(`rgb(${r}, ${g}, ${b})`)
  }
  return colArray
}

/**
 * Form input for brain
 * @returns {{output: *, input: *}[]}
 */
function createInput () {
  const input = []
  const output = []
  for (const [key, vals] of Object.entries(colorList)) {

    let hslVals = []
    console.log(vals)
    vals.forEach(({ lightness, hex }) => {
      const { r, g, b } = hexToRgb(hex)
      const res = {
        ['r' + lightness]: to100(r),
        ['g' + lightness]: to100(g),
        ['b' + lightness]: to100(b)
      }
      hslVals = {...hslVals, ...res}
    })

    console.log('hsl', hslVals)

    const temp = []
    const gradVals = [vals[0], vals[vals.length - 1]]
    vals.forEach((v, index) => {
      let resObj = {}
      gradVals.forEach((val, index) => {
        const count = index === 0 ? 1 : 2
        const { r, g, b } = hexToRgb(val.hex)
        resObj = {
          ...resObj,
          ['r' + count]: to100(r),
          ['g' + count]: to100(g),
          ['b' + count]: to100(b)
        }
      })
      input.push(resObj)
      output.push(hslVals)
    })

  }

  return input.map((curr, index) => ({
    input: curr,
    output: output[index]
  }))
}

/**
 * Convert from percentage to 265-bit
 * @param val
 * @returns {number}
 */
function to256 (val) {
  return (val * 256) - 1
}

/**
 * Convert from 256-bit to percentage
 * @param val
 * @returns {number}
 */
function to100 (val) {
  return (val + 1) / 256
}

/**
 * Convert HEX String to RGB Object
 * @param {String} hex
 * @returns {{r: number, b: number, g: number}|null} Object
 */
function hexToRgb (hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null
}
