export default {
  steel: [
    { lightness: 50, hex: '#f6f9f9' },
    { lightness: 100, hex: '#eff4f5' },
    { lightness: 200, hex: '#dde7ea' },
    { lightness: 300, hex: '#c5d3dd' },
    { lightness: 400, hex: '#9ca3af' },
    { lightness: 500, hex: '#6f89ad' },
    { lightness: 600, hex: '#53658d' },
    { lightness: 700, hex: '#454e70' },
    { lightness: 800, hex: '#373c56' },
    { lightness: 900, hex: '#2c3144' },
  ],
  wisteria: [
    { lightness: 50, hex: '#f7f8f9' },
    { lightness: 100, hex: '#f1f3f6' },
    { lightness: 200, hex: '#e1e3ed' },
    { lightness: 300, hex: '#cdcde3' },
    { lightness: 400, hex: '#aca7d2' },
    { lightness: 500, hex: '#877ebe' },
    { lightness: 600, hex: '#685aa1' },
    { lightness: 700, hex: '#524580' },
    { lightness: 800, hex: '#403660' },
    { lightness: 900, hex: '#332d4b' },
  ],
  denim: [
    { lightness: 50, hex: '#f7f8f9' },
    { lightness: 100, hex: '#f1f3f6' },
    { lightness: 200, hex: '#e0e3ee' },
    { lightness: 300, hex: '#cccce5' },
    { lightness: 400, hex: '#aca6d7' },
    { lightness: 500, hex: '#887cc5' },
    { lightness: 600, hex: '#6858a9' },
    { lightness: 700, hex: '#524487' },
    { lightness: 800, hex: '#403565' },
    { lightness: 900, hex: '#332c4f' },
  ],
  plum: [
    { lightness: 50, hex: '#f8f8f9' },
    { lightness: 100, hex: '#f5f2f4' },
    { lightness: 200, hex: '#eae1ea' },
    { lightness: 300, hex: '#dec8db' },
    { lightness: 400, hex: '#cc9fc3' },
    { lightness: 500, hex: '#b775a7' },
    { lightness: 600, hex: '#955185' },
    { lightness: 700, hex: '#6f3e6a' },
    { lightness: 800, hex: '#533151' },
    { lightness: 900, hex: '#402840' },
  ],
  blush: [
    { lightness: 50, hex: '#f9f8f8' },
    { lightness: 100, hex: '#f6f3f3' },
    { lightness: 200, hex: '#ede2e7' },
    { lightness: 300, hex: '#e2c9d5' },
    { lightness: 400, hex: '#d2a0b7' },
    { lightness: 500, hex: '#c07595' },
    { lightness: 600, hex: '#9f5172' },
    { lightness: 700, hex: '#773e5b' },
    { lightness: 800, hex: '#583148' },
    { lightness: 900, hex: '#44283a' },
  ],
  chestnut: [
    { lightness: 50, hex: '#f9f9f8' },
    { lightness: 100, hex: '#f7f3f2' },
    { lightness: 200, hex: '#f0e1e4' },
    { lightness: 300, hex: '#e7c8cf' },
    { lightness: 400, hex: '#db9dac' },
    { lightness: 500, hex: '#ce7385' },
    { lightness: 600, hex: '#ae4f62' },
    { lightness: 700, hex: '#833c50' },
    { lightness: 800, hex: '#602f41' },
    { lightness: 900, hex: '#4a2735' },
  ],
  sepia: [
    { lightness: 50, hex: '#f9f9f8' },
    { lightness: 100, hex: '#f7f3f2' },
    { lightness: 200, hex: '#eee3e2' },
    { lightness: 300, hex: '#e5cbcc' },
    { lightness: 400, hex: '#d6a2a6' },
    { lightness: 500, hex: '#c5787e' },
    { lightness: 600, hex: '#a4535b' },
    { lightness: 700, hex: '#7b404b' },
    { lightness: 800, hex: '#5b323e' },
    { lightness: 900, hex: '#462933' },
  ],
  cooper: [
    { lightness: 50, hex: '#f9f9f8' },
    { lightness: 100, hex: '#f7f4f1' },
    { lightness: 200, hex: '#eee5e0' },
    { lightness: 300, hex: '#e4cdc6' },
    { lightness: 400, hex: '#d3a69c' },
    { lightness: 500, hex: '#bf7d70' },
    { lightness: 600, hex: '#9d5850' },
    { lightness: 700, hex: '#754343' },
    { lightness: 800, hex: '#573439' },
    { lightness: 900, hex: '#432a2f' },
  ],
  beaver: [
    { lightness: 50, hex: '#f9f9f8' },
    { lightness: 100, hex: '#f6f4f1' },
    { lightness: 200, hex: '#ebe6df' },
    { lightness: 300, hex: '#ded1c5' },
    { lightness: 400, hex: '#c7ac9a' },
    { lightness: 500, hex: '#ac846e' },
    { lightness: 600, hex: '#885f4d' },
    { lightness: 700, hex: '#674942' },
    { lightness: 800, hex: '#4d3838' },
    { lightness: 900, hex: '#3c2e2f' },
  ],
  shadow: [
    { lightness: 50, hex: '#f8f9f8' },
    { lightness: 100, hex: '#f5f4f2' },
    { lightness: 200, hex: '#e9e6e2' },
    { lightness: 300, hex: '#dad1cb' },
    { lightness: 400, hex: '#c0aca6' },
    { lightness: 500, hex: '#a2847d' },
    { lightness: 600, hex: '#7f605b' },
    { lightness: 700, hex: '#61494b' },
    { lightness: 800, hex: '#49393e' },
    { lightness: 900, hex: '#392e33' },
  ]
}
