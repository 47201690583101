<template>
  <div class="home">
    <h1>Palette Generator</h1>
    <form action="" id="chooser">

      <label for="color-chooser">Choose colour </label>
      <input v-model="currentColour" id="color-chooser" name="color-chooser" type="color" />

      <!--
      <label for="amount-chooser">Amount </label>
      <input v-model="currentAmount" id="amount-chooser" name="amount-chooser" type="range" min="3" max="20" />

      <label for="brightness-chooser">Brightness </label>
      <input v-model="currentBrightness" id="brightness-chooser" name="brightness-chooser" type="range" min="1" max="100" />
      -->

      <input @click.prevent.stop="createPalette()" id="color-submitter" type="submit" value="Generate palette" />

    </form>
    <div>
      <ColorList v-for="(col, index) in allColours.slice().reverse()" :colors="[col.colour]" palette="shades" :amount="col.amount" :brightness="col.brightness" :key="index" />
    </div>
  </div>
</template>

<script>
import ColorList from '@/components/modules/ColorList'

export default {
  components: { ColorList },
  data () {
    return {
      currentAmount: '',
      currentBrightness: '',
      currentColour: '#7f35eb',
      allColours: []
    }
  },
  computed: {
    allColoursReverse () {
      return this.allColours.slice().reverse()
    }
  },
  methods: {
    createPalette () {
      this.allColours.push({
        colour: this.currentColour,
        brightness: this.currentBrightness,
        amount: this.currentAmount
      })
    }
  }
}
</script>

<style>
#chooser {
  margin-bottom: 20px;
}

input {
  height: 30px;
  margin: 0 10px 0 0;
  padding: 0px 8px;
  vertical-align: middle;
  background: #ddd;
  border: 0;
}

</style>
