/* eslint-disable */
import tinycolor from 'tinycolor2'
import colorList from './colors/colorList'
import training1 from './training/training-1.json'
import training2 from './training/training-2.json'
import training3 from './training/training-3.json'

const LOAD_TRAINING = true
const CURRENT_TRAINING = training3
const OUTPUT_TO_FUNCTION = false

const net = new brain.NeuralNetwork({
  activation: 'sigmoid',
  hiddenLayers: [5, 4, 3, 2]
})

loadOrTrain()

/**
 * Take one colour, output an array of x colours in various shades
 * @param colors
 * @returns {*[]|*}
 */
export default function (colors) {
  const color = tinycolor(colors[0])
  if (!color.isValid()) {
    console.log('Colour not valid:', colors[0])
    return colors
  }

  const colorRgb = color.toRgb()
  const result = net.run({
    r: to100(colorRgb.r),
    g: to100(colorRgb.g),
    b: to100(colorRgb.b)
  })

  const formattedResult = convertResult(result)
  return [
    // '#fff',
    ...formattedResult
  ]
}

/**
 * Load or train
 */
function loadOrTrain () {
  if (LOAD_TRAINING) {
    loadNet(CURRENT_TRAINING)
  } else {
    trainNet()
  }
  if (OUTPUT_TO_FUNCTION) {
    console.log(net.toFunction())
  }
}

/**
 * Load brain
 * @param json - JSON to load
 */
function loadNet (json) {
  net.fromJSON(json)
}

/**
 * Train brain
 */
function trainNet () {
  const shuffle = (array) => array.sort(() => Math.random() - 0.5)
  const input = shuffle(createInput())
  console.log('Training input', input)
  net.train(input, {
    //iterations: 15000,
    //learningRate: 0.001,
    learningRate: 0.1,
    errorThresh: 0.0002,
    iterations: 40000,
    logPeriod: 1000,
    log: (error) => console.log(error)
  })
  const json = net.toJSON()
  console.log(JSON.stringify(json))
}

/**
 * Convert brain output into array of colours
 * @param input
 * @returns {[]}
 */
function convertResult (input) {
  const cols = {}
  for (const [name, val] of Object.entries(input)) {
    cols[name.substring(1)] = cols[name.substring(1)] || {}
    cols[name.substring(1)][name[0]] = to256(val.toFixed(2))
  }

  const colArray = []
  for (const { r, g, b } of Object.values(cols)) {
    colArray.push(`rgb(${r}, ${g}, ${b})`)
  }
  return colArray
}

/**
 * Form input for brain
 * @returns {{output: *, input: *}[]}
 */
function createInput () {
  const input = []
  const output = []
  for (const [key, vals] of Object.entries(colorList)) {

    let hslVals = []
    vals.forEach(({ lightness, hex }) => {
      const { r, g, b } = hexToRgb(hex)
      const res = {
        ['r' + lightness]: to100(r),
        ['g' + lightness]: to100(g),
        ['b' + lightness]: to100(b)
      }
      hslVals = {...hslVals, ...res}
    })

    const temp = []
    vals.forEach(val => {
      const { r, g, b } = hexToRgb(val.hex)
      input.push({
        r: to100(r),
        g: to100(g),
        b: to100(b)
      })
      output.push(hslVals)
    })
  }

  return input.map((curr, index) => ({
    input: curr,
    output: output[index]
  }))
}

/**
 * Convert from percentage to 265-bit
 * @param val
 * @returns {number}
 */
function to256 (val) {
  return (val * 256) - 1
}

/**
 * Convert from 256-bit to percentage
 * @param val
 * @returns {number}
 */
function to100 (val) {
  return (val + 1) / 256
}

/**
 * Convert HEX String to RGB Object
 * @param {String} hex
 * @returns {{r: number, b: number, g: number}|null} Object
 */
function hexToRgb (hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null
}
