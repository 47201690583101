export default {
  limegreen: [
    { lightness: 50, hex: '#f2faf7' },
    { lightness: 100, hex: '#e4f9ea' },
    { lightness: 200, hex: '#c2f5c7' },
    { lightness: 300, hex: '#8cec9b' },
    { lightness: 400, hex: '#36db5c' },
    { lightness: 500, hex: '#12c72e' },
    { lightness: 600, hex: '#0ead1e' },
    { lightness: 700, hex: '#148e20' },
    { lightness: 800, hex: '#166d22' },
    { lightness: 900, hex: '#145720' },
  ],
    pacific: [
    { lightness: 50, hex: '#eef9f9' },
    { lightness: 100, hex: '#d5f7f6' },
    { lightness: 200, hex: '#a6f0ec' },
    { lightness: 300, hex: '#6be4e1' },
    { lightness: 400, hex: '#26cfd1' },
    { lightness: 500, hex: '#0cb4bb' },
    { lightness: 600, hex: '#0b969f' },
    { lightness: 700, hex: '#107980' },
    { lightness: 800, hex: '#135d62' },
    { lightness: 900, hex: '#114b4e' },
  ],
    steelTwo: [
    { lightness: 50, hex: '#f2fafc' },
    { lightness: 100, hex: '#ddf7fa' },
    { lightness: 200, hex: '#b1edf5' },
    { lightness: 300, hex: '#7cdef1' },
    { lightness: 400, hex: '#38c2ec' },
    { lightness: 500, hex: '#159fe4' },
    { lightness: 600, hex: '#107cd2' },
    { lightness: 700, hex: '#1463ad' },
    { lightness: 800, hex: '#144b7f' },
    { lightness: 900, hex: '#123d62' },
  ],
    denim: [
    { lightness: 50, hex: '#f4fafc' },
    { lightness: 100, hex: '#e3f6fb' },
    { lightness: 200, hex: '#bee8f8' },
    { lightness: 300, hex: '#92d4f7' },
    { lightness: 400, hex: '#55b1f5' },
    { lightness: 500, hex: '#2987f2' },
    { lightness: 600, hex: '#1d63e8' },
    { lightness: 700, hex: '#1d4eca' },
    { lightness: 800, hex: '#1a3c98' },
    { lightness: 900, hex: '#173175' },
  ],
    royalblue: [
    { lightness: 50, hex: '#f4f8fc' },
    { lightness: 100, hex: '#e9f0fb' },
    { lightness: 200, hex: '#d0dbf9' },
    { lightness: 300, hex: '#b4c0f9' },
    { lightness: 400, hex: '#8f95f8' },
    { lightness: 500, hex: '#6968f7' },
    { lightness: 600, hex: '#5047f2' },
    { lightness: 700, hex: '#4138dd' },
    { lightness: 800, hex: '#342db0' },
    { lightness: 900, hex: '#2a268a' },
  ],
    flamingo: [
    { lightness: 50, hex: '#f9f6f9' },
    { lightness: 100, hex: '#f7eaf8' },
    { lightness: 200, hex: '#f1cbf4' },
    { lightness: 300, hex: '#eca7f0' },
    { lightness: 400, hex: '#ea75ea' },
    { lightness: 500, hex: '#e94ae5' },
    { lightness: 600, hex: '#d72ed6' },
    { lightness: 700, hex: '#ac24b5' },
    { lightness: 800, hex: '#821f88' },
    { lightness: 900, hex: '#661b68' },
  ],
    cerise: [
    { lightness: 50, hex: '#fcf9f9' },
    { lightness: 100, hex: '#fceef4' },
    { lightness: 200, hex: '#fad1e8' },
    { lightness: 300, hex: '#f9a9d6' },
    { lightness: 400, hex: '#fa71b6' },
    { lightness: 500, hex: '#fa4693' },
    { lightness: 600, hex: '#f32a6c' },
    { lightness: 700, hex: '#d42056' },
    { lightness: 800, hex: '#a71b42' },
    { lightness: 900, hex: '#831734' },
  ],
    mango: [
    { lightness: 50, hex: '#fbf6ef' },
    { lightness: 100, hex: '#fbefd9' },
    { lightness: 200, hex: '#f8deaf' },
    { lightness: 300, hex: '#f6c471' },
    { lightness: 400, hex: '#f39c34' },
    { lightness: 500, hex: '#f37417' },
    { lightness: 600, hex: '#e7500f' },
    { lightness: 700, hex: '#c53c14' },
    { lightness: 800, hex: '#9f3019' },
    { lightness: 900, hex: '#802719' },
  ],
    orange: [
    { lightness: 50, hex: '#f9f6e9' },
    { lightness: 100, hex: '#faf1c5' },
    { lightness: 200, hex: '#f6e786' },
    { lightness: 300, hex: '#f1d541' },
    { lightness: 400, hex: '#e7b715' },
    { lightness: 500, hex: '#e09607' },
    { lightness: 600, hex: '#cc7205' },
    { lightness: 700, hex: '#a85508' },
    { lightness: 800, hex: '#87420e' },
    { lightness: 900, hex: '#6d3610' },
  ],
    sunglow: [
    { lightness: 50, hex: '#faf8ed' },
    { lightness: 100, hex: '#faf5cc' },
    { lightness: 200, hex: '#f5ed89' },
    { lightness: 300, hex: '#eddc42' },
    { lightness: 400, hex: '#dec114' },
    { lightness: 500, hex: '#cda106' },
    { lightness: 600, hex: '#b07e04' },
    { lightness: 700, hex: '#8b5f07' },
    { lightness: 800, hex: '#6c490c' },
    { lightness: 900, hex: '#553a0e' },
  ],

    olive: [
    { lightness: 50, hex: '#fbfbfa' },
    { lightness: 100, hex: '#f8f9f1' },
    { lightness: 200, hex: '#edefd6' },
    { lightness: 300, hex: '#dfdeaf' },
    { lightness: 400, hex: '#c0bc6f' },
    { lightness: 500, hex: '#969839' },
    { lightness: 600, hex: '#697221' },
    { lightness: 700, hex: '#4c571f' },
    { lightness: 800, hex: '#37401e' },
    { lightness: 900, hex: '#29321c' },
  ],
    sepiaTwo: [
    { lightness: 50, hex: '#fbfbfa' },
    { lightness: 100, hex: '#f8f7f4' },
    { lightness: 200, hex: '#f0e9e5' },
    { lightness: 300, hex: '#e7d1ce' },
    { lightness: 400, hex: '#d7a7a4' },
    { lightness: 500, hex: '#c17c74' },
    { lightness: 600, hex: '#98564d' },
    { lightness: 700, hex: '#6b413e' },
    { lightness: 800, hex: '#4b3232' },
    { lightness: 900, hex: '#38282a' },
  ],
    chestnutTwo: [
    { lightness: 50, hex: '#fbfbfa' },
    { lightness: 100, hex: '#f8f7f5' },
    { lightness: 200, hex: '#efe8e7' },
    { lightness: 300, hex: '#e5d1d2' },
    { lightness: 400, hex: '#d5a6ac' },
    { lightness: 500, hex: '#bf7b7f' },
    { lightness: 600, hex: '#955557' },
    { lightness: 700, hex: '#694145' },
    { lightness: 800, hex: '#4a3136' },
    { lightness: 900, hex: '#37272c' },
  ],
    wisteriaTwo: [
    { lightness: 50, hex: '#fafafb' },
    { lightness: 100, hex: '#f7f6f7' },
    { lightness: 200, hex: '#ece7eb' },
    { lightness: 300, hex: '#e0d0dc' },
    { lightness: 400, hex: '#cca5c1' },
    { lightness: 500, hex: '#b07a9d' },
    { lightness: 600, hex: '#865475' },
    { lightness: 700, hex: '#5f4059' },
    { lightness: 800, hex: '#433143' },
    { lightness: 900, hex: '#322835' },
  ],
    plumTwo: [
    { lightness: 50, hex: '#fafafb' },
    { lightness: 100, hex: '#f7f6f7' },
    { lightness: 200, hex: '#ece7ec' },
    { lightness: 300, hex: '#e0cedf' },
    { lightness: 400, hex: '#cda3c6' },
    { lightness: 500, hex: '#b377a5' },
    { lightness: 600, hex: '#89527d' },
    { lightness: 700, hex: '#613f5f' },
    { lightness: 800, hex: '#443047' },
    { lightness: 900, hex: '#332738' },
  ],
    orchid: [
    { lightness: 50, hex: '#fafafb' },
    { lightness: 100, hex: '#f6f6f8' },
    { lightness: 200, hex: '#eae6ef' },
    { lightness: 300, hex: '#ddcee4' },
    { lightness: 400, hex: '#c7a2d1' },
    { lightness: 500, hex: '#aa76b7' },
    { lightness: 600, hex: '#805191' },
    { lightness: 700, hex: '#5b3e6f' },
    { lightness: 800, hex: '#413051' },
    { lightness: 900, hex: '#31273e' },
  ],
    blushTwo: [
    { lightness: 50, hex: '#fbfafb' },
    { lightness: 100, hex: '#f8f6f7' },
    { lightness: 200, hex: '#efe5ec' },
    { lightness: 300, hex: '#e6cbdf' },
    { lightness: 400, hex: '#d99dc5' },
    { lightness: 500, hex: '#c770a4' },
    { lightness: 600, hex: '#a04b7c' },
    { lightness: 700, hex: '#71395f' },
    { lightness: 800, hex: '#4e2c46' },
    { lightness: 900, hex: '#3a2437' },
  ],
    hotpink: [
    { lightness: 50, hex: '#fbfafa' },
    { lightness: 100, hex: '#f9f6f6' },
    { lightness: 200, hex: '#f1e5ea' },
    { lightness: 300, hex: '#eacad9' },
    { lightness: 400, hex: '#df9cba' },
    { lightness: 500, hex: '#d06f92' },
    { lightness: 600, hex: '#ab4b69' },
    { lightness: 700, hex: '#7a3951' },
    { lightness: 800, hex: '#542c3e' },
    { lightness: 900, hex: '#3f2331' },
  ],
    salmon: [
    { lightness: 50, hex: '#fbfafa' },
    { lightness: 100, hex: '#faf6f5' },
    { lightness: 200, hex: '#f3e5e6' },
    { lightness: 300, hex: '#edcad1' },
    { lightness: 400, hex: '#e59baa' },
    { lightness: 500, hex: '#da6f7c' },
    { lightness: 600, hex: '#b84a55' },
    { lightness: 700, hex: '#853843' },
    { lightness: 800, hex: '#5c2b35' },
    { lightness: 900, hex: '#44232b' },
  ],
    cooperTwo: [
    { lightness: 50, hex: '#fbfafa' },
    { lightness: 100, hex: '#f9f6f5' },
    { lightness: 200, hex: '#f1e7e6' },
    { lightness: 300, hex: '#e9ced2' },
    { lightness: 400, hex: '#dca2ab' },
    { lightness: 500, hex: '#cb767e' },
    { lightness: 600, hex: '#a45056' },
    { lightness: 700, hex: '#743d44' },
    { lightness: 800, hex: '#512f36' },
    { lightness: 900, hex: '#3c252c' },
  ],
    pine: [
    { lightness: 50, hex: '#f8fafa' },
    { lightness: 100, hex: '#f2f7f4' },
    { lightness: 200, hex: '#e1ede4' },
    { lightness: 300, hex: '#c9dccf' },
    { lightness: 400, hex: '#98bba9' },
    { lightness: 500, hex: '#64977c' },
    { lightness: 600, hex: '#467257' },
    { lightness: 700, hex: '#395846' },
    { lightness: 800, hex: '#2d4339' },
    { lightness: 900, hex: '#24352f' },
  ],
    manatee: [
    { lightness: 50, hex: '#fafafa' },
    { lightness: 100, hex: '#f6f6f6' },
    { lightness: 200, hex: '#eae8ea' },
    { lightness: 300, hex: '#dcd2da' },
    { lightness: 400, hex: '#c4a9bc' },
    { lightness: 500, hex: '#a37f96' },
    { lightness: 600, hex: '#7a5a6e' },
    { lightness: 700, hex: '#584556' },
    { lightness: 800, hex: '#3f3441' },
    { lightness: 900, hex: '#302a34' },
  ],
    wisteriaThree: [
    { lightness: 50, hex: '#f9fafb' },
    { lightness: 100, hex: '#f4f6f8' },
    { lightness: 200, hex: '#e7e7ef' },
    { lightness: 300, hex: '#d6cfe4' },
    { lightness: 400, hex: '#bba6d2' },
    { lightness: 500, hex: '#977bb9' },
    { lightness: 600, hex: '#705695' },
    { lightness: 700, hex: '#514273' },
    { lightness: 800, hex: '#3b3354' },
    { lightness: 900, hex: '#2e2941' },
  ],
    denimTwo: [
    { lightness: 50, hex: '#f9fafb' },
    { lightness: 100, hex: '#f3f5f9' },
    { lightness: 200, hex: '#e4e4f2' },
    { lightness: 300, hex: '#d3cceb' },
    { lightness: 400, hex: '#b7a1df' },
    { lightness: 500, hex: '#9475cf' },
    { lightness: 600, hex: '#6e51b3' },
    { lightness: 700, hex: '#503f8c' },
    { lightness: 800, hex: '#3b3164' },
    { lightness: 900, hex: '#2e284d' },
  ],
    orchidTwo: [
    { lightness: 50, hex: '#f9fafb' },
    { lightness: 100, hex: '#f5f4f9' },
    { lightness: 200, hex: '#e8e3f2' },
    { lightness: 300, hex: '#d9c9eb' },
    { lightness: 400, hex: '#c49cdf' },
    { lightness: 500, hex: '#a96fd0' },
    { lightness: 600, hex: '#824bb3' },
    { lightness: 700, hex: '#5d3a8c' },
    { lightness: 800, hex: '#432d64' },
    { lightness: 900, hex: '#33254d' },
  ],
    violet: [
    { lightness: 50, hex: '#fafafb' },
    { lightness: 100, hex: '#f6f4f8' },
    { lightness: 200, hex: '#ece1f1' },
    { lightness: 300, hex: '#e1c6e9' },
    { lightness: 400, hex: '#d297db' },
    { lightness: 500, hex: '#bf6ac8' },
    { lightness: 600, hex: '#9947a9' },
    { lightness: 700, hex: '#6d3683' },
    { lightness: 800, hex: '#4d2b5e' },
    { lightness: 900, hex: '#3a2348' },
  ],
    hotpinkTwo: [
    { lightness: 50, hex: '#fbfafa' },
    { lightness: 100, hex: '#f9f4f6' },
    { lightness: 200, hex: '#f3e0eb' },
    { lightness: 300, hex: '#edc3dd' },
    { lightness: 400, hex: '#e792c2' },
    { lightness: 500, hex: '#e064a0' },
    { lightness: 600, hex: '#c34178' },
    { lightness: 700, hex: '#8f325c' },
    { lightness: 800, hex: '#642745' },
    { lightness: 900, hex: '#4b2036' },
  ],
    blushThree: [
    { lightness: 50, hex: '#fcfaf9' },
    { lightness: 100, hex: '#fbf5f4' },
    { lightness: 200, hex: '#f6e2e4' },
    { lightness: 300, hex: '#f1c5cd' },
    { lightness: 400, hex: '#ed94a3' },
    { lightness: 500, hex: '#e76775' },
    { lightness: 600, hex: '#ce434f' },
    { lightness: 700, hex: '#9b3340' },
    { lightness: 800, hex: '#6d2834' },
    { lightness: 900, hex: '#51202a' },
  ],
    salmonTwo: [
    { lightness: 50, hex: '#fbfaf9' },
    { lightness: 100, hex: '#fbf5f2' },
    { lightness: 200, hex: '#f6e4df' },
    { lightness: 300, hex: '#f1c9c2' },
    { lightness: 400, hex: '#ec9a8e' },
    { lightness: 500, hex: '#e56e5c' },
    { lightness: 600, hex: '#ca493b' },
    { lightness: 700, hex: '#973732' },
    { lightness: 800, hex: '#6b2b2b' },
    { lightness: 900, hex: '#4f2225' },
  ],
    cooperThree: [
    { lightness: 50, hex: '#fbfaf9' },
    { lightness: 100, hex: '#faf6f2' },
    { lightness: 200, hex: '#f3e7df' },
    { lightness: 300, hex: '#edcfc1' },
    { lightness: 400, hex: '#e3a38e' },
    { lightness: 500, hex: '#d6785a' },
    { lightness: 600, hex: '#b35239' },
    { lightness: 700, hex: '#813e31' },
    { lightness: 800, hex: '#5a302b' },
    { lightness: 900, hex: '#432624' },
  ],
    chocolateTwo: [
    { lightness: 50, hex: '#faf7f1' },
    { lightness: 100, hex: '#fbf1de' },
    { lightness: 200, hex: '#f7e3b5' },
    { lightness: 300, hex: '#f3cb79' },
    { lightness: 400, hex: '#eea439' },
    { lightness: 500, hex: '#eb7d1a' },
    { lightness: 600, hex: '#d95811' },
    { lightness: 700, hex: '#b14214' },
    { lightness: 800, hex: '#893319' },
    { lightness: 900, hex: '#6c2a18' },
  ],
    sunglowTwo: [
    { lightness: 50, hex: '#faf9f3' },
    { lightness: 100, hex: '#f8f5e1' },
    { lightness: 200, hex: '#f2ebb5' },
    { lightness: 300, hex: '#e9d879' },
    { lightness: 400, hex: '#d7b838' },
    { lightness: 500, hex: '#c29518' },
    { lightness: 600, hex: '#a1710f' },
    { lightness: 700, hex: '#7c5512' },
    { lightness: 800, hex: '#5e4117' },
    { lightness: 900, hex: '#493417' },
  ],
    asparagus: [
    { lightness: 50, hex: '#f7f9f6' },
    { lightness: 100, hex: '#f2f5eb' },
    { lightness: 200, hex: '#e3ecd0' },
    { lightness: 300, hex: '#cddca9' },
    { lightness: 400, hex: '#9dc170' },
    { lightness: 500, hex: '#6da042' },
    { lightness: 600, hex: '#527d2c' },
    { lightness: 700, hex: '#46612b' },
    { lightness: 800, hex: '#3a4a2a' },
    { lightness: 900, hex: '#2f3b25' },
  ],
    pineTwo: [
    { lightness: 50, hex: '#f3f8f8' },
    { lightness: 100, hex: '#e7f5f2' },
    { lightness: 200, hex: '#ccece2' },
    { lightness: 300, hex: '#a4ddcf' },
    { lightness: 400, hex: '#5fc4b0' },
    { lightness: 500, hex: '#30a48c' },
    { lightness: 600, hex: '#26836e' },
    { lightness: 700, hex: '#28675b' },
    { lightness: 800, hex: '#26504a' },
    { lightness: 900, hex: '#21413d' },
  ],
    pacificTwo: [
    { lightness: 50, hex: '#f3f8f9' },
    { lightness: 100, hex: '#e6f4f5' },
    { lightness: 200, hex: '#cbe9eb' },
    { lightness: 300, hex: '#a5d8df' },
    { lightness: 400, hex: '#66bccd' },
    { lightness: 500, hex: '#3898b7' },
    { lightness: 600, hex: '#2c769b' },
    { lightness: 700, hex: '#2c5c7d' },
    { lightness: 800, hex: '#284860' },
    { lightness: 900, hex: '#223b4d' },
  ],
    denimThree: [
    { lightness: 50, hex: '#f3f8fa' },
    { lightness: 100, hex: '#e6f3f7' },
    { lightness: 200, hex: '#cae6f1' },
    { lightness: 300, hex: '#a5d3eb' },
    { lightness: 400, hex: '#6cb3e3' },
    { lightness: 500, hex: '#3e8cd9' },
    { lightness: 600, hex: '#3069c5' },
    { lightness: 700, hex: '#2f52a2' },
    { lightness: 800, hex: '#29407a' },
    { lightness: 900, hex: '#23355f' },
  ],
    royalblueTwo: [
    { lightness: 50, hex: '#f4f7fa' },
    { lightness: 100, hex: '#eaf1f8' },
    { lightness: 200, hex: '#d3e1f2' },
    { lightness: 300, hex: '#b7cbed' },
    { lightness: 400, hex: '#8ba7e6' },
    { lightness: 500, hex: '#617edf' },
    { lightness: 600, hex: '#4b5bcd' },
    { lightness: 700, hex: '#4147ab' },
    { lightness: 800, hex: '#363881' },
    { lightness: 900, hex: '#2c2f64' },
  ],
    wisteriaFour: [
    { lightness: 50, hex: '#f5f7f9' },
    { lightness: 100, hex: '#eef0f7' },
    { lightness: 200, hex: '#dbdff1' },
    { lightness: 300, hex: '#c5c7eb' },
    { lightness: 400, hex: '#a3a1e3' },
    { lightness: 500, hex: '#8077d9' },
    { lightness: 600, hex: '#6454c6' },
    { lightness: 700, hex: '#5141a4' },
    { lightness: 800, hex: '#41347b' },
    { lightness: 900, hex: '#342c5f' },
  ],
    blushFour: [
    { lightness: 50, hex: '#f9f8f8' },
    { lightness: 100, hex: '#f6f0f3' },
    { lightness: 200, hex: '#eddee7' },
    { lightness: 300, hex: '#e4c4d6' },
    { lightness: 400, hex: '#d79abc' },
    { lightness: 500, hex: '#ca6f9e' },
    { lightness: 600, hex: '#ad4c7d' },
    { lightness: 700, hex: '#843a65' },
    { lightness: 800, hex: '#632e4f' },
    { lightness: 900, hex: '#4d273f' },
  ],
    coralTwo: [
    { lightness: 50, hex: '#faf8f5' },
    { lightness: 100, hex: '#faf1eb' },
    { lightness: 200, hex: '#f5e0d4' },
    { lightness: 300, hex: '#f9cbaa' },
    { lightness: 400, hex: '#ea9c77' },
    { lightness: 500, hex: '#e57249' },
    { lightness: 600, hex: '#ce4e31' },
    { lightness: 700, hex: '#a33b2e' },
    { lightness: 800, hex: '#7b2e2c' },
    { lightness: 900, hex: '#5f2626' },
  ],
    royalblueThree: [
    { lightness: 50, hex: '#f6fafd' },
    { lightness: 100, hex: '#e7f5fd' },
    { lightness: 200, hex: '#c7e3fb' },
    { lightness: 300, hex: '#a3cbfa' },
    { lightness: 400, hex: '#729ffa' },
    { lightness: 500, hex: '#4271f9' },
    { lightness: 600, hex: '#2e4ef5' },
    { lightness: 700, hex: '#344cca' },
    { lightness: 800, hex: '#2030b0' },
    { lightness: 900, hex: '#1a2888' },
  ],
    indigoTwo: [
    { lightness: 50, hex: '#f5f8fc' },
    { lightness: 100, hex: '#eaf0fc' },
    { lightness: 200, hex: '#d3d8fa' },
    { lightness: 300, hex: '#babcf9' },
    { lightness: 400, hex: '#9b8ff9' },
    { lightness: 500, hex: '#7962f9' },
    { lightness: 600, hex: '#5c41f4' },
    { lightness: 700, hex: '#4834e0' },
    { lightness: 800, hex: '#382ab4' },
    { lightness: 900, hex: '#2d248e' },
  ],
    flamingoTwo: [
    { lightness: 50, hex: '#f9f7fa' },
    { lightness: 100, hex: '#f6edf9' },
    { lightness: 200, hex: '#eed1f5' },
    { lightness: 300, hex: '#e7aff1' },
    { lightness: 400, hex: '#e27ded' },
    { lightness: 500, hex: '#dd51e8' },
    { lightness: 600, hex: '#c433d9' },
    { lightness: 700, hex: '#9628b6' },
    { lightness: 800, hex: '#6e2188' },
    { lightness: 900, hex: '#551d67' },
  ],
    blushFive: [
    { lightness: 50, hex: '#fcf9f9' },
    { lightness: 100, hex: '#fcf0f3' },
    { lightness: 200, hex: '#fad3e6' },
    { lightness: 300, hex: '#f9acd1' },
    { lightness: 400, hex: '#fa73ac' },
    { lightness: 500, hex: '#fa4885' },
    { lightness: 600, hex: '#f32b5e' },
    { lightness: 700, hex: '#d4214b' },
    { lightness: 800, hex: '#a61b3b' },
    { lightness: 900, hex: '#81172f' },
  ],
    tomatoTwo: [
    { lightness: 50, hex: '#fcf9f6' },
    { lightness: 100, hex: '#fcf1ed' },
    { lightness: 200, hex: '#fadad6' },
    { lightness: 300, hex: '#f8b9b1' },
    { lightness: 400, hex: '#f88777' },
    { lightness: 500, hex: '#f85b48' },
    { lightness: 600, hex: '#ee392e' },
    { lightness: 700, hex: '#cb2b2b' },
    { lightness: 800, hex: '#9d2328' },
    { lightness: 900, hex: '#7b1d23' },
  ],
    mangoTwo: [
    { lightness: 50, hex: '#fbf8f2' },
    { lightness: 100, hex: '#fbf1e1' },
    { lightness: 200, hex: '#f8e1bb' },
    { lightness: 300, hex: '#f5c782' },
    { lightness: 400, hex: '#f39d40' },
    { lightness: 500, hex: '#f1741e' },
    { lightness: 600, hex: '#e24f13' },
    { lightness: 700, hex: '#bc3b16' },
    { lightness: 800, hex: '#922e1a' },
    { lightness: 900, hex: '#732619' },
  ],
    carrotTwo: [
    { lightness: 50, hex: '#fbf9f4' },
    { lightness: 100, hex: '#faf6e0' },
    { lightness: 200, hex: '#f5ebb0' },
    { lightness: 300, hex: '#efd76f' },
    { lightness: 400, hex: '#e4b52e' },
    { lightness: 500, hex: '#d89012' },
    { lightness: 600, hex: '#ba6a0a' },
    { lightness: 700, hex: '#8e500e' },
    { lightness: 800, hex: '#693c12' },
    { lightness: 900, hex: '#503013' },
  ],
    oliveTwo: [
    { lightness: 50, hex: '#fbfaf6' },
    { lightness: 100, hex: '#f9f8e6' },
    { lightness: 200, hex: '#f2eebb' },
    { lightness: 300, hex: '#e9dd7f' },
    { lightness: 400, hex: '#d4bd3a' },
    { lightness: 500, hex: '#b99a17' },
    { lightness: 600, hex: '#91740d' },
    { lightness: 700, hex: '#6a5810' },
    { lightness: 800, hex: '#4d4214' },
    { lightness: 900, hex: '#3b3314' },
  ],
    pineThree: [
    { lightness: 50, hex: '#f5fafa' },
    { lightness: 100, hex: '#eaf7f5' },
    { lightness: 200, hex: '#d0eee7' },
    { lightness: 300, hex: '#aadfd7' },
    { lightness: 400, hex: '#66c3ba' },
    { lightness: 500, hex: '#33a296' },
    { lightness: 600, hex: '#257f72' },
    { lightness: 700, hex: '#25645b' },
    { lightness: 800, hex: '#214c47' },
    { lightness: 900, hex: '#1c3d3a' },
  ],
    denimFive: [
    { lightness: 50, hex: '#f3fafc' },
    { lightness: 100, hex: '#e2f7fb' },
    { lightness: 200, hex: '#bceaf7' },
    { lightness: 300, hex: '#8ed9f4' },
    { lightness: 400, hex: '#4db8f0' },
    { lightness: 500, hex: '#2291eb' },
    { lightness: 600, hex: '#196ddc' },
    { lightness: 700, hex: '#1b56b9' },
    { lightness: 800, hex: '#194289' },
    { lightness: 900, hex: '#153569' },
  ],
}
